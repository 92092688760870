import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import ButtonSlim from 'web-app-components/components/ButtonSlim';

import TextField from './components/TextField';

import makeFriendlyUrl from '../../../../utils/makeFriendlyUrl';

const FriendlyURLField = ({
  titleFieldName,
  followTitle,
  onFollowTitleClick,
  onUnfollowTitle,
  ...restProps
}) => {
  const { values, setFieldValue } = useFormikContext();

  const title = values[titleFieldName];

  useEffect(() => {
    let timeoutHandle;

    if (followTitle) {
      timeoutHandle = setTimeout(() => {
        setFieldValue(restProps.name, makeFriendlyUrl(title));
      }, 500);
    }

    return () => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
      }
    };
  }, [followTitle, title, setFieldValue, restProps.name]);

  const handle_Change = () => onUnfollowTitle();

  return (
    <div>
      <TextField {...restProps} _onChange={handle_Change} />
      {!followTitle && (
        <ButtonSlim onClick={onFollowTitleClick}>
          Nustatyti automatiškai
        </ButtonSlim>
      )}
    </div>
  );
};

FriendlyURLField.propTypes = {
  name: PropTypes.string.isRequired,
  titleFieldName: PropTypes.string.isRequired,
  followTitle: PropTypes.bool.isRequired,
  onFollowTitleClick: PropTypes.func.isRequired,
  onUnfollowTitle: PropTypes.func.isRequired,
};

export default FriendlyURLField;
