import { BrowserRouter as Router } from 'react-router-dom';
import useAxiosReqAuthIntcpt from 'web-app-components/hooks/useAxiosReqAuthIntcpt';
import { useAuthContext } from 'web-app-components/components/AuthProvider';

import axiosInstance from './utils/axiosInstance';
import endpoints from './utils/endpoints';

import Switch from './Switch';

import AdminApp from './features/AdminApp';

const axiosReqAuthIntcptConfig = {
  shouldExcludeUrl: (url) => url === endpoints.auth,
};

const App = () => {
  const {
    tokenValid,
    state: { token },
  } = useAuthContext();

  const httpClientCofigured = useAxiosReqAuthIntcpt(
    axiosInstance,
    token,
    axiosReqAuthIntcptConfig
  );

  return (
    <Router>
      {tokenValid && httpClientCofigured ? <AdminApp /> : <Switch />}
    </Router>
  );
};

export default App;
