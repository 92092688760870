import makeRestClient from 'web-app-components/utils/makeRestClient';

import axiosInstance from '../utils/axiosInstance';
import endpoints from '../utils/endpoints';

const client = {
  ...makeRestClient(axiosInstance, endpoints.goods),
  import: async () => {
    await axiosInstance.post(`${endpoints.goods}/import`);
  },
  importInfo: async () => {
    const { data } = await axiosInstance.get(`${endpoints.goods}/import/info`);
    return data;
  },
  importStop: async () => {
    await axiosInstance.post(`${endpoints.goods}/import/stop`);
  },
  publish: async () => {
    await axiosInstance.post(`${endpoints.goods}/publish`);
  },
};

export default client;
