import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import profilesClient from '../../../../clients/profiles';
import jobsClient from '../../../../clients/jobs';

import ProfileSelectInput from './components/ProfileSelectInput';
import Job from './components/Job';

const useStyles = makeStyles((theme) => ({
  controls: {
    marginBottom: theme.spacing(2),
  },

  jobs: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}));

const Jobs = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [profiles, setProfiles] = useState();

  const [profileId, setProfileId] = useState();

  const [jobs, setJobs] = useState();

  useEffect(() => {
    (async () => {
      try {
        setProfiles(await profilesClient.find());
      } catch (error) {
        enqueueSnackbar(
          'Nepavyko gauti transporto profilių. Pabandykite perkrauti puslapį.',
          { variant: 'error' }
        );
      }
    })();
  }, [enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      try {
        if (profileId) {
          setJobs(await jobsClient.find({ profile: profileId }));
        }
      } catch (error) {
        enqueueSnackbar(
          'Nepavyko gauti transporto profilio veiksmų. Pabandykite perkrauti puslapį.',
          { variant: 'error' }
        );
      }
    })();
  }, [profileId, enqueueSnackbar]);

  const handleProfileChange = ({ target: { value: profileId } }) => {
    setProfileId(profileId);
  };

  return (
    <div>
      <div className={classes.controls}>
        {profiles ? (
          <ProfileSelectInput
            profiles={profiles}
            value={profileId ?? ''}
            onChange={handleProfileChange}
            fullWidth
          />
        ) : (
          <CircularProgress />
        )}
      </div>
      <div>
        {profileId ? (
          <div className={classes.jobs}>
            {jobs ? (
              jobs.map((job) => <Job key={job.id} data={job} />)
            ) : (
              <CircularProgress />
            )}
          </div>
        ) : (
          <Typography>Pasirinkite transporto profilį viršuje</Typography>
        )}
      </div>
    </div>
  );
};

export default Jobs;
