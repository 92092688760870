import React from 'react';
import PropTypes from 'prop-types';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const RunJobDialog = ({ job, onSubmit, onClose, ...restProps }) => {
  return (
    <Dialog onClose={onClose} {...restProps}>
      <DialogTitle>
        Ar tikrai norite pradėti veiksmo "{job.title}" vykdymą?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Veiksmas gali užtrukti ilgai ir sulėtinti sistemos ir kitų veiksmų
          veikimą.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonSlim onClick={onClose}>Atšaukti</ButtonSlim>
        <ButtonPrimary onClick={onSubmit}>Vykdyti</ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
};

RunJobDialog.propTypes = {
  job: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,

  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RunJobDialog;
