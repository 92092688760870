import React from 'react';
import PropTypes from 'prop-types';
import SelectField from 'web-app-components/components/SelectField';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

const MainCategorySelectField = ({ categories, ...restProps }) => {
  return categories ? (
    <SelectField {...restProps}>
      {categories.map(({ id, title }) => (
        <MenuItem key={id} value={id}>
          {title}
        </MenuItem>
      ))}
    </SelectField>
  ) : (
    <CircularProgress />
  );
};

MainCategorySelectField.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default MainCategorySelectField;
