import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from 'web-app-components/components/SelectInput';
import MenuItem from '@material-ui/core/MenuItem';

const ProfileSelectInput = ({ profiles, ...restProps }) => {
  return (
    <SelectInput label="Transporto profilis" {...restProps}>
      <MenuItem value={''}>Visi</MenuItem>
      {profiles?.map((profile) => (
        <MenuItem key={profile.id} value={profile.id}>
          {profile.title}
        </MenuItem>
      ))}
    </SelectInput>
  );
};

ProfileSelectInput.propTypes = {
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ProfileSelectInput;
