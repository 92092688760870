import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Formik } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import goodsClient from '../../../../../../clients/goods';

import { toForm, fromForm } from '../../utils/formData';
import makeFriendlyUrl from '../../utils/makeFriendlyUrl';

import Form from '../../components/Form';

import PRODUCT_E_SHOP_ACTIONS from './utils/PRODUCT_E_SHOP_ACTIONS';
import validationSchema from './utils/validationSchema';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: 1,
  },
}));

const Good = ({ id }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState();
  const [friendlyUrlFollowTitle, setFriendlyUrlFollowTitle] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const data = await goodsClient.findOne(id);
        setData(data);
        setFriendlyUrlFollowTitle(
          data.friendlyUrl === data.friendlyUrl_default
        );
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti prekės', { variant: 'error' });
      }
    })();
  }, [id, enqueueSnackbar]);

  const handleFriendlyUrlFollowTitleClick = () =>
    setFriendlyUrlFollowTitle(true);

  const handleFriendlyUrlUnfollowTitle = () => setFriendlyUrlFollowTitle(false);

  const handleSubmit = async (values) => {
    try {
      const data = fromForm(values);
      await goodsClient.update(id, {
        ...data,
        friendlyUrl_default: makeFriendlyUrl(values.title),
        // NOTE after every potential edit set product to be updated in e-shop
        action_eShop: PRODUCT_E_SHOP_ACTIONS.update,
      });
      enqueueSnackbar('Prekė išsaugota', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(
        'Nepavyko išsaugoti prekės. Gali būti, kad priežastis yra prekė su vienodu adresu. Pakeiskite prekės adresą ir bandykite dar kartą.',
        {
          variant: 'error',
          autoHideDuration: 10 * 1000,
        }
      );
    }
  };

  return data ? (
    <div>
      <div className={classes.header}>
        <div className={classes.title}>
          <Typography variant="h5">Prekė "{data.title}"</Typography>
          <Typography variant="subtitle1">
            <div>Tiekėjo kodas: {data.id_export}</div>
            <div>Kiekis: {data.stock ?? 'nežinomas'}</div>
            <div>
              Atnaujinta: {moment(data.updated_at).format('YYYY-MM-DD HH:mm')}
            </div>
          </Typography>
        </div>
      </div>
      <Formik
        initialValues={toForm(data)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form
            {...props}
            friendlyUrlFollowTitle={friendlyUrlFollowTitle}
            onFriendlyUrlFollowTitleClick={handleFriendlyUrlFollowTitleClick}
            onFriendlyUrlUnfollowTitle={handleFriendlyUrlUnfollowTitle}
            profile={data.profile}
          />
        )}
      </Formik>
    </div>
  ) : (
    <CircularProgress />
  );
};

Good.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Good;
