import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import sanitizeDescription from './utils/sanitizeDescription';

import Parameters from './components/Parameters';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabContent: {
    flex: 1,
    overflowX: 'scroll',
    overflowY: 'scroll',
  },
  tabContentHidden: {
    display: 'none',
  },
}));

const OriginalDescription = React.forwardRef(
  (
    {
      className,
      description_pol,
      description_eng,
      description_lt,
      parameters,
      ...restProps
    },
    ref
  ) => {
    const classes = useStyles();

    const [tab, setTab] = useState(0);

    const handleTabChange = (_, tab) => setTab(tab);

    const tabContents = [description_pol, description_eng, description_lt];

    return (
      <div ref={ref} className={clsx([classes.root, className])} {...restProps}>
        <Tabs variant="fullWidth" value={tab} onChange={handleTabChange}>
          <Tab label="Lenkų k." />
          <Tab label="Anglų k." />
          <Tab label="Lietuvių k." />
        </Tabs>
        <div className={classes.tabContent}>
          <div
            dangerouslySetInnerHTML={sanitizeDescription(tabContents[tab])}
          />
          {parameters && <Parameters parameters={parameters} />}
        </div>
      </div>
    );
  }
);

OriginalDescription.propTypes = {
  description_eng: PropTypes.string,
  description_pol: PropTypes.string,
  description_lt: PropTypes.string,
  parameters: PropTypes.any,
};

export default OriginalDescription;
