const endpoints = {
  auth: '/auth/local',

  goods: '/goods',
  categories: '/categories',
  profiles: '/profiles',
  jobs: '/jobs',
};

export default endpoints;
