import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Form as FormikForm } from 'formik';
import CheckboxField from 'web-app-components/components/CheckboxField';
import TextField from 'web-app-components/components/TextField';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import PriceField from 'goods-common/components/PriceField';

import categoriesClient from '../../../.././../../clients/categories';

import DescriptionField from './components/DescriptionField';
import FriendlyURLField from './components/FriendlyURLField';
import CategoriesSelectField from './components/CategoriesSelectField';
import MainCategorySelectField from './components/MainCategorySelectField';

const MAX_IMAGE_HEIGHT_PX = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  titleFieldContent: {
    display: 'flex',

    '& > *': {
      flex: 1,
    },

    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    // NOTE + 8 because 4px padding top and bottom
    maxHeight: `${MAX_IMAGE_HEIGHT_PX + 8}px`,
    overflowY: 'scroll',

    '& > *': {
      padding: theme.spacing(1),
    },
  },
  image: {
    maxHeight: `${MAX_IMAGE_HEIGHT_PX}px`,
  },
  dimensions: {
    display: 'flex',

    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const Form = ({
  isSubmitting,
  values,
  friendlyUrlFollowTitle,
  onFriendlyUrlFollowTitleClick,
  onFriendlyUrlUnfollowTitle,
  profile,
}) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [categories, setCategories] = useState();

  useEffect(() => {
    (async () => {
      try {
        setCategories(
          await categoriesClient.find({
            profile: profile.id,
            _sort: 'title:asc',
          })
        );
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti kategorijų pasirinkimui', {
          variant: 'error',
        });
      }
    })();
  }, [profile, enqueueSnackbar]);

  return (
    <FormikForm>
      <div className={classes.root}>
        <div>
          <Typography variant="h6">Pavadinimas</Typography>
          <div className={classes.titleFieldContent}>
            <div>
              <TextField name="title" label="Lietuvių k." fullWidth />
            </div>
            <div>
              <div>
                <Typography>Lenkų k.</Typography>
                <Typography>{values.title_pol}</Typography>
              </div>
              <div>
                <Typography>Anglų k.</Typography>
                <Typography>{values.title_eng}</Typography>
              </div>
              <div>
                <Typography>Lietuvių k.</Typography>
                <Typography>{values.title_lt}</Typography>
              </div>
            </div>
          </div>
        </div>
        <DescriptionField
          name="description"
          label="Aprašymas"
          description_pol={values.description_pol}
          description_eng={values.description_eng}
          description_lt={values.description_lt}
          parameters={values.parameters}
          disabled={isSubmitting}
        />
        <TextField name="metaTitle" label="Meta pavadinimas" fullWidth />
        <TextField name="metaDescription" label="Meta aprašymas" fullWidth />
        <FriendlyURLField
          name="friendlyUrl"
          label="Adresas"
          titleFieldName="title"
          followTitle={friendlyUrlFollowTitle}
          onFollowTitleClick={onFriendlyUrlFollowTitleClick}
          onUnfollowTitle={onFriendlyUrlUnfollowTitle}
          fullWidth
        />
        <div>
          <Typography variant="h6">Nuotraukos</Typography>
          <div className={classes.imagesContainer}>
            {values.images.map(({ url }) => (
              <img
                className={classes.image}
                key={url}
                alt="prekės paveikslėlis"
                src={url}
              />
            ))}
          </div>
        </div>
        <div>
          <Typography variant="h6">Ikonos</Typography>
          <div className={classes.imagesContainer}>
            {values.icons.map(({ url }) => (
              <img
                className={classes.image}
                key={url}
                alt="prekės ikona"
                src={url}
              />
            ))}
          </div>
        </div>
        <div className={classes.dimensions}>
          <TextField name="width" label="Plotis, m" fullWidth disabled />
          <TextField name="height" label="Aukštis, m" fullWidth disabled />
          <TextField name="depth" label="Gylis, m" fullWidth disabled />
          <TextField name="weight" label="Svoris, kg" fullWidth disabled />
        </div>
        <div>
          <Typography variant="h6">Kaina</Typography>
          <PriceField name="price" label="Kaina" />
        </div>
        <CategoriesSelectField
          categories={categories}
          name="categories"
          label="Kategorijos"
          fullWidth
        />
        <MainCategorySelectField
          categories={
            categories &&
            values.categories.map((id) =>
              categories.find((category) => category.id === id)
            )
          }
          name="mainCategory"
          label="Pagrindinė kategorija"
          fullWidth
        />
        <div>
          <CheckboxField name="prepared" label="Kelti į el. parduotuvę" />
        </div>
        <ButtonPrimary type="submit" disabled={isSubmitting}>
          Išsaugoti
        </ButtonPrimary>
      </div>
    </FormikForm>
  );
};

const imageBasePropTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

Form.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    title_pol: PropTypes.string,
    title_eng: PropTypes.string,
    title_lt: PropTypes.string,
    description_pol: PropTypes.string,
    description_eng: PropTypes.string,
    description_lt: PropTypes.string,
    parameters: PropTypes.any,
    images: PropTypes.arrayOf(PropTypes.shape(imageBasePropTypes)).isRequired,
    icons: PropTypes.arrayOf(PropTypes.shape(imageBasePropTypes)).isRequired,
    categories: PropTypes.array,
  }).isRequired,
  friendlyUrlFollowTitle: PropTypes.bool.isRequired,
  onFriendlyUrlFollowTitleClick: PropTypes.func.isRequired,
  onFriendlyUrlUnfollowTitle: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default Form;
