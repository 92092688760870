import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import TextInput from 'web-app-components/components/TextInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';

import profilesClient from '../../../../../../clients/profiles';
import goodsClient from '../../../../../../clients/goods';

import FilterSelectInput, { filters } from './components/FilterSelectInput';
import ProfileSelectInput from './components/ProfileSelectInput';

const PAGE_SIZE = 10;

const useStyles = makeStyles((theme) => ({
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

let searchTimeoutHandle = null;

const GoodsList = () => {
  const classes = useStyles();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.has('page')
    ? parseInt(searchParams.get('page'))
    : 0;
  const profile = searchParams.has('profile')
    ? searchParams.get('profile')
    : '';
  const filter = searchParams.has('filter')
    ? searchParams.get('filter')
    : filters.ALL;
  const search = searchParams.has('search') ? searchParams.get('search') : '';

  const history = useHistory();
  const { path } = useRouteMatch();

  const { enqueueSnackbar } = useSnackbar();

  const [profiles, setProfiles] = useState();

  const [searchValue, setSearchValue] = useState(search);

  const [loadingGoods, setLoadingGoods] = useState(true);
  const [goods, setGoods] = useState();
  const [goodCount, setGoodCount] = useState();

  useEffect(() => {
    (async () => {
      try {
        setProfiles(await profilesClient.find());
      } catch (error) {
        enqueueSnackbar(
          'Nepavyko gauti transporto profilių. Pabandykite perkrauti puslapį.',
          { variant: 'error' }
        );
      }
    })();
  }, [enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      setLoadingGoods(true);
      try {
        const query =
          filter === filters.PREPARED
            ? { prepared_eq: true }
            : filter === filters.UNPREPARED
            ? { prepared_eq: false }
            : {};

        if (profile) {
          query.profile = profile;
        }

        if (search) {
          query._q = search;
        }

        query._start = page * PAGE_SIZE;
        query._limit = PAGE_SIZE;
        query._sort = 'stock:desc';

        setGoods(await goodsClient.find(query));
        setGoodCount(await goodsClient.count(query));
      } catch (error) {
        enqueueSnackbar('Nepavyko gauti prekių', { variant: 'error' });
      }
      setLoadingGoods(false);
    })();
  }, [profile, search, filter, page, enqueueSnackbar]);

  const handleProfileChange = ({ target: { value } }) => {
    searchParams.set('profile', value);
    searchParams.set('page', 0);
    history.push(`${path}?${searchParams.toString()}`);
  };

  const handleSearchChange = ({ target: { value } }) => {
    setSearchValue(value);

    if (searchTimeoutHandle !== null) {
      clearTimeout(searchTimeoutHandle);
    }
    searchTimeoutHandle = setTimeout(() => {
      searchParams.set('search', value);
      searchParams.set('page', 0);
      history.push(`${path}?${searchParams.toString()}`);

      searchTimeoutHandle = null;
    }, 500);
  };

  const handleFilterChange = ({ target: { value } }) => {
    searchParams.set('filter', value);
    searchParams.set('page', 0);
    history.push(`${path}?${searchParams.toString()}`);
  };

  const makeGoodClickHandler = (id) => () => history.push(`${path}/${id}`);

  const handlePageChange = (_, page) => {
    searchParams.set('page', page);
    history.push(`${path}?${searchParams.toString()}`);
  };

  return (
    <div>
      <div className={classes.controls}>
        {profiles ? (
          <ProfileSelectInput
            profiles={profiles}
            value={profile}
            onChange={handleProfileChange}
            fullWidth
          />
        ) : (
          <CircularProgress />
        )}
        <TextInput
          label="Paieška"
          value={searchValue}
          onChange={handleSearchChange}
        />
        <FilterSelectInput
          label="Rodinys"
          value={filter}
          onChange={handleFilterChange}
        />
      </div>
      {loadingGoods ? (
        <CircularProgress />
      ) : goods ? (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pavadinimas (lenkų k.)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goods.map((good) => (
                  <TableRow
                    key={good.id}
                    onClick={makeGoodClickHandler(good.id)}
                  >
                    <TableCell>
                      {good.title_pol || good.title_eng || good.title_lt}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={goodCount}
            page={page}
            rowsPerPage={PAGE_SIZE}
            rowsPerPageOptions={[PAGE_SIZE]}
            onPageChange={handlePageChange}
          />
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default GoodsList;
