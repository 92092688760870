import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import RichTextField from 'web-app-components/components/RichTextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import OriginalDescription from './components/OriginalDescription';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',

    '& > *': {
      flex: 1,
    },

    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  original: {
    height: 0,
    // NOTE without this the div expands in width beyond the display, because
    // its parents don't have set width. i believe this property causes the
    // browser to not make this div wider than 100 px at first, but later the
    // flex: 1 kicks in and makes the div take up half the width, as it should.
    width: '100px',
  },
}));

const DescriptionField = ({
  name,
  label,
  description_pol,
  description_eng,
  description_lt,
  parameters,
  disabled,
}) => {
  const classes = useStyles();

  const descriptionEditorContainerRef = useRef();
  const originalDescriptionContainerRef = useRef();

  useEffect(() => {
    const editorContainer = descriptionEditorContainerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      const {
        contentRect: { height },
      } = entries[0];
      originalDescriptionContainerRef.current.style.height = `${height}px`;
    });
    resizeObserver.observe(editorContainer);
    return () => resizeObserver.unobserve(editorContainer);
  }, []);

  return (
    <div>
      <Typography variant="h6">{label}</Typography>
      <div className={classes.content}>
        <div>
          <div ref={descriptionEditorContainerRef}>
            <RichTextField
              name={name}
              label="Lietuvių k."
              disabled={disabled}
            />
          </div>
        </div>
        <OriginalDescription
          ref={originalDescriptionContainerRef}
          className={classes.original}
          description_pol={description_pol}
          description_eng={description_eng}
          description_lt={description_lt}
          parameters={parameters}
        />
      </div>
    </div>
  );
};

DescriptionField.defaultProps = {
  disabled: false,
};

DescriptionField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description_pol: PropTypes.string,
  description_eng: PropTypes.string,
  description_lt: PropTypes.string,
  parameters: PropTypes.any,
  disabled: PropTypes.bool,
};

export default DescriptionField;
