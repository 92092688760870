import React, { useEffect, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import profilesClient from '../../../../../../clients/profiles';
import categoriesClient from '../../../../../../clients/categories';

import ProfileSelectInput from './components/ProfileSelectInput';

const useStyles = makeStyles((theme) => ({
  controls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),

    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const CategoriesList = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [profiles, setProfiles] = useState();
  const [profile, setProfile] = useState('');

  const [loadingCategories, setLoadingCategories] = useState(false);
  const [categories, setCategories] = useState();
  const [importingCategories, setImportingCategories] = useState(false);

  const loadCategories = useCallback(async () => {
    setLoadingCategories(true);
    try {
      setCategories(
        await categoriesClient.find({
          profile: profile || undefined,
          _sort: 'title:asc',
        })
      );
    } catch (error) {
      enqueueSnackbar('Nepavyko gauti kategorijų', { variant: 'error' });
    }
    setLoadingCategories(false);
  }, [profile, enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      try {
        setProfiles(await profilesClient.find());
      } catch (error) {
        enqueueSnackbar(
          'Nepavyko gauti transporto profilių. Pabandykite perkrauti puslapį.',
          { variant: 'error' }
        );
      }
    })();
  }, [enqueueSnackbar]);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  const handleProfileChange = ({ target: { value } }) => {
    setProfile(value);
  };

  const handleImportClick = async () => {
    setImportingCategories(true);
    try {
      await categoriesClient.import(profile);
      enqueueSnackbar('Kategorijos sėkmingai importuotos', {
        variant: 'success',
      });
      await loadCategories();
    } catch (error) {
      enqueueSnackbar('Nepavyko importuoti kategorijų', { variant: 'error' });
    }
    setImportingCategories(false);
  };

  return (
    <div>
      <div className={classes.controls}>
        {profiles ? (
          <ProfileSelectInput
            profiles={profiles}
            value={profile}
            onChange={handleProfileChange}
            fullWidth
          />
        ) : (
          <CircularProgress />
        )}
        {profile && (
          <ButtonSlim
            onClick={handleImportClick}
            disabled={importingCategories}
          >
            Importuoti iš el. parduotuvės
          </ButtonSlim>
        )}
      </div>
      {loadingCategories ? (
        <CircularProgress />
      ) : (
        categories && (
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Pavadinimas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories.map((category) => (
                    <TableRow key={category.id}>
                      <TableCell>{category.title}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )
      )}
    </div>
  );
};

export default CategoriesList;
