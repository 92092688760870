import { Route, Switch as RRDSwitch, useRouteMatch } from 'react-router-dom';

import Good from './features/Good';
import GoodsList from './features/GoodsList';

const Switch = () => {
  const { path } = useRouteMatch();

  return (
    <RRDSwitch>
      <Route
        path={`${path}/:id`}
        render={({
          match: {
            params: { id },
          },
        }) => <Good id={parseInt(id)} />}
      />
      <Route path={`${path}`}>
        <GoodsList />
      </Route>
    </RRDSwitch>
  );
};

export default Switch;
