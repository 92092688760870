import { Route, Switch as RRDSwitch } from 'react-router-dom';

import Jobs from './features/Jobs';
import Goods from './features/Goods';
import Categories from './features/Categories';

const Switch = () => {
  return (
    <RRDSwitch>
      <Route path="/goods">
        <Goods />
      </Route>
      <Route path="/categories">
        <Categories />
      </Route>
      <Route path="/">
        <Jobs />
      </Route>
    </RRDSwitch>
  );
};

export default Switch;
