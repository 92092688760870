import makeRestClient from 'web-app-components/utils/makeRestClient';

import axiosInstance from '../utils/axiosInstance';
import endpoints from '../utils/endpoints';

const client = {
  ...makeRestClient(axiosInstance, endpoints.categories),
  import: async (profileId) => {
    await axiosInstance.post(
      `${endpoints.categories}/updatefromeshop?profile=${profileId}`
    );
  },
};

export default client;
