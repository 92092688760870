import {
  valueToForm,
  boolToForm,
  arrayToForm,
  formToNumber,
} from 'web-app-components/utils/formInputs';

export const toForm = ({
  title_pol,
  title_eng,
  title_lt,
  title,
  description_pol,
  description_eng,
  description_lt,
  parameters,
  description,
  metaTitle,
  metaDescription,
  friendlyUrl,
  images,
  icons,
  price,
  width,
  height,
  depth,
  weight,
  categories,
  mainCategory,
  prepared,
}) => ({
  title_pol: valueToForm(title_pol),
  title_eng: valueToForm(title_eng),
  title_lt: valueToForm(title_lt),
  title: valueToForm(title),
  description_pol: valueToForm(description_pol),
  description_eng: valueToForm(description_eng),
  description_lt: valueToForm(description_lt),
  parameters,
  description: valueToForm(description),
  metaTitle: valueToForm(metaTitle),
  metaDescription: valueToForm(metaDescription),
  friendlyUrl: valueToForm(friendlyUrl),
  images,
  icons,
  price: {
    ...price,
    base: valueToForm(price?.base),
    baseCurrencyRate: valueToForm(price?.baseCurrencyRate),
    delivery: valueToForm(price?.delivery),
    vat: valueToForm(price?.vat),
    profit: valueToForm(price?.profit),
  },
  width: valueToForm(width),
  height: valueToForm(height),
  depth: valueToForm(depth),
  weight: valueToForm(weight),
  categories: arrayToForm(categories).map(({ id }) => id),
  mainCategory: mainCategory ? mainCategory.id : '',
  prepared: boolToForm(prepared),
});

export const fromForm = ({
  title,
  description,
  metaTitle,
  metaDescription,
  friendlyUrl,
  price,
  categories,
  mainCategory,
  prepared,
}) => ({
  title,
  description,
  metaTitle,
  metaDescription,
  friendlyUrl,
  price: {
    ...price,
    base: formToNumber(price.base),
    baseCurrencyRate: formToNumber(price.baseCurrencyRate),
    delivery: formToNumber(price.delivery),
    vat: formToNumber(price.vat),
    profit: formToNumber(price.profit),
  },
  categories,
  mainCategory: formToNumber(mainCategory),
  prepared,
});
