import { object, string } from 'yup';

const validationSchema = object({
  title: string()
    .max(128, 'Pavadinimas negali būti ilgesnis nei 128 simboliai')
    .test(
      'title-not-empty-prepared',
      'Prekės be pavadinimo negali būti keliamos į el. parduotuvę',
      (value, context) =>
        !context.parent.prepared || (value && value.length > 0)
    ),
  description: string().max(
    21844,
    'Aprašymas negali būti ilgesnis nei 21844 simboliai'
  ),
  metaTitle: string().max(
    128,
    'Meta pavadinimas negali būti ilgesnis nei 128 simboliai. Rekomenduojamas ilgis yra 70.'
  ),
  metaDescription: string().max(
    512,
    'Meta aprašymas negali būti ilgesnis nei 512 simbolių. Rekomenduojamas ilgis yra 160.'
  ),
  friendlyUrl: string()
    .max(128, 'Adresas negali būti ilgesnis nei 128 simboliai')
    .test(
      'friendly-url-not-empty-prepared',
      'Prekės be adreso negali būti keliamos į el. parduotuvę',
      (value, context) =>
        !context.parent.prepared || (value && value.length > 0)
    ),
});

export default validationSchema;
