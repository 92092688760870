import { useSnackbar } from 'notistack';
import { Formik, Form } from 'formik';
import { useAuthContext } from 'web-app-components/components/AuthProvider';
import TextField from 'web-app-components/components/TextField';
import ButtonPrimary from 'web-app-components/components/ButtonPrimary';
import makeStyles from '@material-ui/core/styles/makeStyles';

import authClient from '../../clients/auth';

import validationSchema from './utils/validationSchema';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  field: {
    marginBottom: theme.spacing(1),
  },
}));

const SignInForm = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const { signIn } = useAuthContext();

  const initialValues = { username: '', password: '' };

  const handleSubmit = async ({ username, password }) => {
    try {
      signIn(await authClient.auth(username, password));
    } catch (error) {
      enqueueSnackbar(
        'Nepavyko prisijungti. Įsitikinkite, kad prisijungimo duomenys teisingi ir bandykite dar kartą.',
        { variant: 'error' }
      );
    }
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={classes.form}>
            <TextField
              className={classes.field}
              name="username"
              label="El. pašto adresas"
            />
            <TextField
              className={classes.field}
              type="password"
              name="password"
              label="Slaptažodis"
            />
            <ButtonPrimary type="submit" disabled={isSubmitting}>
              Prisijungti
            </ButtonPrimary>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignInForm;
