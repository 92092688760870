import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import PARAMETER_TYPES from './utils/PARAMETER_TYPES';

const useStyles = makeStyles((theme) => ({
  sectionWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  parameterWrapper: {
    display: 'flex',
  },
  parameterName: {
    flex: 3,
    fontWeight: 'bold',
  },
  parameterValue: {
    flex: 7,
  },
}));

const Parameters = ({ parameters }) => {
  const classes = useStyles();

  return (
    <div>
      {parameters.map(({ type, name, value }, index) => (
        <div key={index}>
          {type === PARAMETER_TYPES.section ? (
            <div className={classes.sectionWrapper}>
              <Typography variant="h6">{name}</Typography>
            </div>
          ) : (
            <div>
              <div className={classes.parameterWrapper}>
                <Typography className={classes.parameterName}>
                  {name}:
                </Typography>
                <Typography className={classes.parameterValue}>
                  {value}
                </Typography>
              </div>
              <Divider />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

Parameters.propTypes = {
  parameters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(PARAMETER_TYPES)).isRequired,
      priority: PropTypes.number,
      value: PropTypes.string,
    })
  ),
};

export default Parameters;
