import ListItemLink from 'web-app-components/components/ListItemLink';
import List from '@material-ui/core/List';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  item: {
    textAlign: 'center',
  },
}));

const Navigation = () => {
  const classes = useStyles();

  return (
    <List>
      <ListItemLink className={classes.item} to="/" exact text="Pagrindinis" />
      <ListItemLink className={classes.item} to="/goods" text="Prekės" />
      <ListItemLink
        className={classes.item}
        to="/categories"
        text="Kategorijos"
      />
    </List>
  );
};

export default Navigation;
