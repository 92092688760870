import React from 'react';
import PropTypes from 'prop-types';
import SelectField from 'web-app-components/components/SelectField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';

import makeCategoriesMap from './utils/makeCategoriesMap';
import sortSelected from './utils/sortSelected';

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}));

const CategoriesSelectField = ({ categories, ...restProps }) => {
  const classes = useStyles();

  const renderValue = (selected) => {
    const categoriesMap = makeCategoriesMap(categories);
    const selectedSorted = sortSelected(selected, categoriesMap);
    return (
      <div className={classes.chips}>
        {selectedSorted.map((id) => (
          <Chip
            className={classes.chip}
            key={id}
            label={categoriesMap[id].title}
          />
        ))}
      </div>
    );
  };

  return categories ? (
    <SelectField multiple renderValue={renderValue} {...restProps}>
      {categories.map(({ id, title }) => (
        <MenuItem key={id} value={id}>
          {title}
        </MenuItem>
      ))}
    </SelectField>
  ) : (
    <CircularProgress />
  );
};

CategoriesSelectField.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CategoriesSelectField;
