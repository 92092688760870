import { Route, Switch as RRDSwitch, useRouteMatch } from 'react-router-dom';

import CategoriesList from './features/CategoriesList';

const Switch = () => {
  const { path } = useRouteMatch();

  return (
    <RRDSwitch>
      <Route path={`${path}`}>
        <CategoriesList />
      </Route>
    </RRDSwitch>
  );
};

export default Switch;
