import sanitizeHtml from 'sanitize-html';

const sanitizeDescription = (description) => ({
  __html: sanitizeHtml(description, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      '*': ['style'],
    },
  }),
});

export default sanitizeDescription;
