import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { fieldToTextField } from 'formik-material-ui';
import TextInput from 'web-app-components/components/TextInput';

const Component = ({ _onChange, ...restProps }) => {
  // NOTE for some reason fieldToTextField returns "variant: undefined" if no
  // variant prop is passed to Field, which clears TextInput's variant value,
  // which is not good for us, so remove variant prop if it's not set to any
  // meaningful value
  const { variant, onChange, ...textFieldProps } = fieldToTextField(restProps);
  if (variant) {
    textFieldProps.variant = variant;
  }

  textFieldProps.onChange = (...args) => {
    _onChange(...args);
    onChange(...args);
  };

  return <TextInput {...textFieldProps} />;
};

Component.propTypes = {
  _onChange: PropTypes.func.isRequired,
};

const TextField = (props) => <Field component={Component} {...props} />;

export default TextField;
