import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from 'web-app-components/components/SelectInput';
import MenuItem from '@material-ui/core/MenuItem';

export const filters = {
  ALL: 'all',
  PREPARED: 'prepared',
  UNPREPARED: 'unprepared',
};

const filtersArray = Object.values(filters);

const FilterSelectInput = (props) => {
  return (
    <SelectInput {...props}>
      <MenuItem value={filters.ALL}>Visos</MenuItem>
      <MenuItem value={filters.PREPARED}>Paruoštos</MenuItem>
      <MenuItem value={filters.UNPREPARED}>Neparuoštos</MenuItem>
    </SelectInput>
  );
};

FilterSelectInput.propTypes = {
  value: PropTypes.oneOf(filtersArray).isRequired,
};

export default FilterSelectInput;
