import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import useDialog from 'web-app-components/hooks/useDialog';
import ButtonSlim from 'web-app-components/components/ButtonSlim';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import jobsClient from '../../../../../../clients/jobs';

import RunJobDialog from './components/RunJobDialog';
import StopJobDialog from './components/StopJobDialog';

const useStyles = makeStyles((theme) => ({
  state: {
    fontWeight: 'bold',
  },
}));

const Job = ({ data }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState();

  const runJobDialog = useDialog();
  const stopJobDialog = useDialog();

  const loadState = useCallback(async () => {
    try {
      setState(await jobsClient.state(data.key));
    } catch (error) {
      // NOTE means the job is not running at the moment
      if (error.response?.status === 409) {
        setState(null);
      }
    }
  }, [data.key]);

  useEffect(() => {
    let timeoutId;

    const pollState = async () => {
      await loadState();
      timeoutId = setTimeout(pollState, 5000);
    };

    pollState();

    return () => timeoutId && clearTimeout(timeoutId);
  }, [loadState]);

  const handleRunClick = () => {
    runJobDialog.setOpen(true);
  };

  const handleRunSubmit = async () => {
    runJobDialog.setOpen(false);

    try {
      await jobsClient.run(data.key);
      // NOTE set to undefined to disable run button. otherwise it will be
      // disabled only after we get a subsequent response from state endpoint,
      // which can take a while, since some tasks do some heavy work, i.e. parse
      // XML, so the backend server is blocked then.
      setState();
      enqueueSnackbar(
        'Veiksmas sėkmingai pradėtas. Gali užtrukti, kol bus pradėta rodyti veiksmo būsena, todėl laukite ir nepradėkite veiksmo dar kartą.',
        {
          variant: 'success',
          autoHideDuration: 10 * 1000,
        }
      );
    } catch (error) {
      enqueueSnackbar('Nepavyko pradėti veiksmo. Bandykite dar kartą.', {
        variant: 'error',
      });
    }
  };

  const handleStopClick = () => {
    stopJobDialog.setOpen(true);
  };

  const handleStopSubmit = async () => {
    stopJobDialog.setOpen(false);

    try {
      await jobsClient.stop(data.key);
      enqueueSnackbar(
        'Veiksmas sėkmingai stabdomas. Gali užtrukti, kol jis visiškai sustos.',
        {
          variant: 'success',
        }
      );
    } catch (error) {
      // NOTE means the job is not running at the moment
      if (error.response?.status === 409) {
        enqueueSnackbar('Veiksmas šiuo metu nėra vykdomas', {
          variant: 'info',
        });
      } else {
        enqueueSnackbar('Nepavyko stabdyti veiksmo. Bandykite dar kartą.', {
          variant: 'error',
        });
      }
    }
  };

  return (
    <Card>
      <CardHeader title={data.title} subheader={data.description} />
      <CardContent>
        {typeof state === 'undefined' ? (
          <CircularProgress />
        ) : state === null ? (
          'Veiksmas šiuo metu nevykdomas'
        ) : (
          <Typography>
            Būsena:{' '}
            <span className={classes.state}>
              {state.state === 'processing'
                ? `vykdoma (${(state.progress * 100).toFixed(2)}%)`
                : 'ruošiamasi'}
            </span>
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <ButtonSlim
          onClick={handleRunClick}
          disabled={typeof state === 'undefined' || state}
        >
          Vykdyti
        </ButtonSlim>
        <ButtonSlim onClick={handleStopClick} disabled={!state}>
          Stabdyti
        </ButtonSlim>
      </CardActions>
      <RunJobDialog job={data} {...runJobDialog} onSubmit={handleRunSubmit} />
      <StopJobDialog
        job={data}
        {...stopJobDialog}
        onSubmit={handleStopSubmit}
      />
    </Card>
  );
};

Job.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

export default Job;
