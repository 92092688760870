import AppBar from 'web-app-components/components/AppBar';
import AppContent from 'web-app-components/components/AppContent';
import AppDrawer, {
  useAppDrawer,
} from 'web-app-components/components/AppDrawer';
import { useAuthContext } from 'web-app-components/components/AuthProvider';
import ButtonSlim from 'web-app-components/components/ButtonSlim';

import Navigation from './Navigation';
import Switch from './Switch';

const App = () => {
  const { signOut } = useAuthContext();

  const appDrawer = useAppDrawer();

  const handleMenuClick = () => appDrawer.setMobileOpen(!appDrawer.mobileOpen);

  const handleSignOutClick = () => signOut();

  return (
    <>
      <AppBar
        text="Prekių perkėlimas"
        actions={
          <ButtonSlim color="inherit" onClick={handleSignOutClick}>
            Atsijungti
          </ButtonSlim>
        }
        onMenuClick={handleMenuClick}
        enableMenu
      ></AppBar>
      <AppDrawer {...appDrawer}>
        <Navigation />
      </AppDrawer>
      <AppContent>
        <Switch />
      </AppContent>
    </>
  );
};

export default App;
